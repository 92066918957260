<template>
  <b-card no-body class="card-statistics">
    <b-card-header>
      <b-card-title class="pb-1">{{$t('instagram.priceDollars')}}</b-card-title>
      <b-card-sub-title class="text-secondary mb-20 font">{{$t('instagram.averageData')}}</b-card-sub-title>
    </b-card-header>
    <b-card-body class="statistics-body">
      <b-row v-for="(item, index) in data" :key="index" class="justify-content-around">
            <b-col lg="2" md="3" sm="6" v-if="item.network === 'instagram'">
              <statistic-card-horizontal
                icon="EyeIcon"
                :statistic="byComma(item.live)"
                :statistic-title="$t('profile.instagram.live')"
                color="danger"
              />
            </b-col>
            <b-col lg="2" md="3" sm="6" v-if="item.network === 'instagram'">
              <statistic-card-horizontal
                icon="BookIcon"
                :statistic="byComma(item.story)"
                :statistic-title="$t('profile.instagram.story')"
                color="info"
              />
            </b-col>
            <b-col lg="2" md="3" sm="6" v-if="item.network === 'instagram'">
              <statistic-card-horizontal
                icon="CameraIcon"
                :statistic="byComma(item.photo)"
                :statistic-title="$t('profile.instagram.photo')"
                color="primary"
              />
            </b-col>
            <b-col lg="2" md="3" sm="6" v-if="item.network === 'instagram'">
              <statistic-card-horizontal
                icon="VideoIcon"
                :statistic="byComma(item.video)"
                :statistic-title="$t('profile.instagram.video')"
                color="warning"
              />
            </b-col>
          </b-row>
    </b-card-body>
  </b-card>
</template>
<script>
import { BRow, BCol, BCard, BCardHeader, BCardBody, BCardTitle, BCardSubTitle } from 'bootstrap-vue'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
export default {
  components: {
    BRow,
    BCol,
    StatisticCardHorizontal,
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BCardSubTitle
  },
  props: {
    data: {
      type: Array,
      required: true
    }
  },
  methods: {
    byComma(num) {
      const val = Number(num).toLocaleString('en-US', {style: 'currency', currency: 'USD'})
      return val.slice(0,-3)
    }
  }
}
</script>
